<template>
  <b-modal v-model="shown" title="Create New Credential" hide-footer @hide="onHideHandler">
    <b-form @submit.prevent="submit" @reset="reset">
      <b-form-group
        label="Username">
        <b-form-input
          v-model="v$.form.username.$model"
          type="text"
          @input.native="handlerInputUsername"
          @keyup.native="handlerInputUsername"
          placeholder="Input username"
          required />
        <b-form-invalid-feedback :state="!v$.form.username.$error" v-if="v$.form.username.$error">
          {{ $t('validation.' + v$.form.username.$errors[0].$validator) }}
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group
        label="Password">
        <b-input-group>
          <b-form-input
            v-model="v$.form.password.$model"
            type="text"
            readonly
            placeholder="Input password"
            required />
            <b-input-group-append>
              <b-button size="sm" @click="handlerBtnRandomPassword" text="Button" variant="secondary">Generate Password</b-button>
            </b-input-group-append>
        </b-input-group>
        <b-form-invalid-feedback :state="!v$.form.password.$error" v-if="v$.form.password.$error">
          {{ $t('validation.' + v$.form.password.$errors[0].$validator) }}
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group
        label="Division">
        <el-select placeholder="Select division (optional)" v-model="form.assigned_division_id" filterable class="w-100">
          <el-option :value="item.id" v-for="(item, i) in divisionList" :key="i" :label="item.name"/>
        </el-select>
      </b-form-group>
      <b-form-group
        label="Callback URL">
        <b-form-input
          v-model="v$.form.callback_url.$model"
          type="text"
          placeholder="Input callback URL"
          required />
        <b-form-invalid-feedback :state="!v$.form.callback_url.$error" v-if="v$.form.callback_url.$error">
          {{ $t('validation.' + v$.form.callback_url.$errors[0].$validator) }}
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group
        label="IP Whitelist">
        <el-tag
          v-for="(val, index) in form.ip_whitelist"
          :key="index"
          class="mr-2"
          closable
          :disable-transitions="false"
          @close="handleClose(index)">
            {{ val }}
        </el-tag>
        <el-input
          class="input-new-tag"
          v-if="input_keyword_visible"
          v-model="input_keyword_value"
          ref="saveTagInput"
          :style="{ width: '150px' }"
          size="mini"
          @keyup.enter.native="handleInputConfirm"
          @blur="handleInputConfirm">
          </el-input>
        <el-button v-else class="button-new-tag" size="small" @click="handlerAddNewKeyword">+ New IP</el-button>
      </b-form-group>
    </b-form>
    <div class="d-flex flex-row-reverse">
      <el-button @click="submit" :loading="loading.submit" class="btn ml-2 btn-primary">{{ $t('general.submit') }}</el-button>
      <b-button @click="onHideHandler" type="reset" variant="secondary">{{ $t('general.cancel') }}</b-button>
    </div>
  </b-modal>
</template>
<script>
import { uniq } from 'lodash';
import useVuelidate from '@vuelidate/core';
import { required, helpers, url } from '@vuelidate/validators';
import passwordComplexity from '@/library/password-complexity';

import notificationAPI from '../../../api/notifications';
import password_generator from '../../../library/password-generator';
import integrations from '../../../api/integrations';
import popupErrorMessages from '../../../library/popup-error-messages';

export default {
  name: 'IntegrationsCreate',
  metaInfo: {
    title: 'Integrations',
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  validations() {
    return {
      form: {
        username: { required },
        password: {
          required,
          complexity: helpers.withMessage(() => 'Password should have at least 1 uppercase, 1 lowercase, 1 number and 1 special character', passwordComplexity),
        },
        callback_url: {
          url,
        },
      },
    };
  },
  computed: {
    activeWorkspace() {
      return this.$store.state.workspace.activeWorkspace;
    },
  },
  data() {
    return {
      input_keyword_visible: false,
      input_keyword_value: '',
      shown: false,
      loading: {
        submit: false,
      },
      form: {
        username: '',
        password: '',
        callback_url: '',
        ip_whitelist: [],
        assigned_division_id: '',
      },
      divisionList: [],
    };
  },
  mounted() {
    this.getDivision();
  },
  methods: {
    onHideHandler(arg) {
      this.showm = false;
      this.$emit('hide', arg);
    },
    reset() {
      this.form = {
        username: '',
        ip_whitelist: [],
        callback_url: '',
        password: password_generator(10),
      };
    },
    async submit() {
      this.loading.submit = true;
      this.form.workspace_id = this.activeWorkspace._id;
      const response = await integrations.submit(this.activeWorkspace._id, this.form);
      popupErrorMessages(response).then(() => {
        this.$message({
          type: 'success',
          message: this.$t('integrations.success_add_new'),
        });
        this.onHideHandler();
        this.$parent.getList();
      }).catch(() => {});
      this.loading.submit = false;
    },
    handlerBtnRandomPassword() {
      this.form.password = password_generator(10);
    },
    handleInputConfirm() {
      if (this.input_keyword_value) {
        this.form.ip_whitelist.push(this.input_keyword_value);
        this.form.ip_whitelist = uniq(this.form.ip_whitelist);
      }
      this.input_keyword_value = '';
      this.input_keyword_visible = false;
    },
    handlerAddNewKeyword() {
      this.input_keyword_visible = !this.input_keyword_visible;
      setTimeout(() => {
        this.$refs.saveTagInput.focus();
      }, 100);
    },
    handleClose(index) {
      this.form.ip_whitelist.splice(index, 1);
    },
    handlerInputUsername(event) {
      const { keyCode } = event;
      if (keyCode === 32) {
        this.form.username = `${this.form.username.replace(/\s/g, '')}_`;
        event.preventDefault();
      }
    },
    getDivision() {
      notificationAPI.getListDivisionTemplate({
        workspace_id: this.activeWorkspace._id,
        channel_id: '-',
      }).then(({ data }) => {
        this.divisionList = data.rows;
      }).catch((err) => {
        console.log(err);
        this.$message({
          type: 'warning',
          message: 'Failed fetch divisions',
        });
      });
    },
  },
  watch: {
    show() {
      this.shown = this.show;
      if (this.show) {
        this.v$.form.$touch();
        this.reset();
      }
    },
  },
};
</script>
